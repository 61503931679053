export const useGeolocation = () => {
  const currentLocation = ref<{ lat: number; lng: number } | null>(null)
  const retrievedTimestamp = ref<number | null>(null)
  const error = ref<string | null>(null)
  const status = ref<PermissionState | null>(null)
  const permission = ref<PermissionStatus | null>(null)
  const pending = ref<boolean>(false)
  const mounted = ref<boolean>(false)

  const onChange: PositionCallback = ({ coords, timestamp }) => {
    pending.value = false

    if (safariPermissionInterval.value) {
      clearInterval(safariPermissionInterval.value)
    }

    currentLocation.value = { lat: coords.latitude, lng: coords.longitude }
    retrievedTimestamp.value = timestamp

    error.value = null
  }

  const onError: PositionErrorCallback = (e) => {
    pending.value = false
    error.value = e.message
    console.error(e)
  }

  watch(status, (status) => {
    if (status === "granted") {
      navigator.geolocation.getCurrentPosition(onChange, onError)
    } else if (status === "denied") {
      error.value = "Location permission denied"
    } else if (status === "prompt") {
      error.value = "Location permission prompt"
    } else if (status === null) {
      error.value = "Location permission not supported"
    }
  })

  const safariPermissionInterval = ref<NodeJS.Timeout>()
  const permissionEventListener = function (this: PermissionStatus, _ev: Event) {
    status.value = this.state
  }

  const mountGeolocationListeners = async () => {
    // https://caniuse.com/?search=navigator.permissions
    // navigator.permissions doesn't work on Safari 15.5 and under
    if (navigator.permissions) {
      permission.value = await navigator.permissions.query({ name: "geolocation" })
      status.value = permission.value.state

      permission.value.addEventListener("change", permissionEventListener, { passive: true })
    } else {
      status.value = null

      // If Safari, do stupid hacky solution
      safariPermissionInterval.value = setInterval(() => {
        navigator.geolocation.getCurrentPosition(onChange, onError)
      }, 2000)
    }

    navigator.geolocation.getCurrentPosition(onChange, onError)

    pending.value = true
    mounted.value = true
  }

  const unmountGeolocationListeners = async () => {
    if (!permission.value) {
      permission.value = await navigator.permissions.query({ name: "geolocation" })
    }

    permission.value.removeEventListener("change", permissionEventListener)

    if (safariPermissionInterval.value) {
      clearInterval(safariPermissionInterval.value)
    }

    navigator.geolocation?.clearWatch(0)
    pending.value = false
    mounted.value = false
    currentLocation.value = null
    retrievedTimestamp.value = null
    error.value = null
    status.value = null
    permission.value = null
  }

  return {
    currentLocation,
    retrievedTimestamp,
    error,
    status,
    pending,
    mountGeolocationListeners,
    unmountGeolocationListeners,
  }
}
