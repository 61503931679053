<template>
  <v-slide-x-transition v-if="!right">
    <v-card
      v-if="modelValue"
      class="d-flex flex-column w-100 h-100 rounded-0 overflow-auto position-absolute"
      v-bind="$attrs"
      style="max-width: 400px; z-index: 1000; left: 0;"
    >
      <template #title>{{ title }}</template>
      <template #subtitle>{{ subtitle }}</template>
      <template #append>
        <v-btn icon="arrow_back" variant="text" :aria-label="$t('back')" @click="() => emit('update:modelValue', false)" />
      </template>

      <v-divider></v-divider>

      <div class="flex-grow-1">
        <slot />
      </div>
    </v-card>
  </v-slide-x-transition>

  <v-slide-x-reverse-transition v-else>
    <v-card
      v-if="modelValue"
      class="d-flex flex-column w-100 h-100 rounded-0 overflow-auto position-absolute"
      v-bind="$attrs"
      style="max-width: 400px; z-index: 1000; right: 0;"
    >
      <template #title>{{ title }}</template>
      <template #subtitle>{{ subtitle }}</template>
      <template #prepend>
        <v-btn icon="arrow_forward" variant="text" :aria-label="$t('back')" @click="() => emit('update:modelValue', false)" />
      </template>

      <v-divider></v-divider>

      <div class="flex-grow-1">
        <slot />
      </div>
    </v-card>
  </v-slide-x-reverse-transition>
</template>

<script setup lang="ts">
defineProps<{
  title?: string
  subtitle?: string
  modelValue: boolean
  right?: boolean
}>()

const emit = defineEmits<{ (event: "update:modelValue", value: boolean): void }>()
</script>
