<template>
  <div
    ref="mapWrapper"
    :style="props.style"
    :class="`${props.class ? props.class : ''} ${$vuetify.theme.current.dark ? 'bg-dark' : 'bg-white'}`"
    class="position-relative d-flex flex-column"
    style="overflow-x: hidden"
  >
    <div class="flex-grow-1 d-flex flex-column relative-position">
      <g-map-map
        ref="mapReference"
        class="w-100 flex-grow-1"
        :center="internalCenter"
        :zoom="props.zoom ?? 7"
        :map-type-id="mapType"
        :options="{
          styles: $vuetify.theme.current.dark ? mapDarkStyle : mapLightStyle,
          mapTypeControl: false,
          fullscreenControl: false,
        }"
      >
        <cluster v-if="showMapContent">
          <g-map-marker
            v-for="asset of assetsForMarkers"
            :key="asset._id"
            :position="geoPointToLatLng(asset?.location?.gps)"
            :title="`[${asset.key}] ${asset.description}`"
            :icon="asset.markerIcon"
            :draggable="draggableMainAsset && asset._id === mainAsset?._id"
            @dragend="emit('dragend', $event)"
            @click="selectedAsset = asset"
          />

          <slot name="custom-markers" v-bind="{ selectedAsset, setSelectedAsset }" />
        </cluster>

        <template v-if="showRelationLines">
          <g-map-polyline
            v-for="relation in selectedAssetRelations"
            :key="relation._id"
            :path="assetRelationToPath(relation)"
            :options="{ strokeColor: $vuetify.theme.current.dark ? '#009cd1' : '#002440', strokeOpacity: 1.0, strokeWeight: 1 }"
          />
          <template v-if="showMainAssetRelations !== false">
            <g-map-polyline
              v-for="relation in mainAssetRelations"
              :key="relation._id"
              :path="assetRelationToPath(relation)"
              :options="{ strokeColor: $vuetify.theme.current.dark ? '#009cd1' : '#002440', strokeOpacity: 1.0, strokeWeight: 1 }"
            />
          </template>
        </template>

        <slot />

        <current-location-marker v-if="geolocationEnabled" />
      </g-map-map>

      <div
        v-if="showMapInnerControls"
        class="position-absolute d-flex"
        style="margin: 10px; left: 0; top: 0;"
        :style="`${drawerOpen && oldMapWrapperWidth > 450 ? 'margin-left: 410px;' : ''}`"
      >
        <div class="d-flex flex-column align-start" style="gap: 8px;">
          <v-tooltip :text="$t('map.layers')" location="end">
            <template #activator="{ props }">
              <v-btn
                v-if="geoLayers.length || geoLayersDuoPP.length"
                v-bind="props"
                :color="mapControlTab === MapControlTab.LAYERS ? 'primary' : 'surface'"
                rounded="sm"
                density="compact"
                icon
                :aria-label="$t('map.layers')"
                style="width: 40px; min-height: 40px"
                @click="() => mapControlTab = mapControlTab === MapControlTab.LAYERS ? MapControlTab.CONTROLS : MapControlTab.LAYERS"
              >
                <v-badge v-if="geoLayers.some((layer) => layer.enabled) || geoLayersDuoPP.some((layer) => layer.enabled)" dot color="error">
                  <v-icon icon="layers"/>
                </v-badge>

                <v-icon v-else icon="layers"/>
              </v-btn>
            </template>
          </v-tooltip>

          <v-tooltip :text="$t('map.filter')" location="end">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                :color="mapControlTab === MapControlTab.FILTERS ? 'primary' : 'surface'"
                rounded="sm"
                density="compact"
                icon
                :aria-label="$t('map.filter')"
                style="width: 40px; min-height: 40px"
                @click="mapControlTab = mapControlTab === MapControlTab.FILTERS ? MapControlTab.CONTROLS : MapControlTab.FILTERS"
              >
                <v-badge v-if="categoryFilters.length > 0 || cityFilters.length > 0" dot color="error">
                  <v-icon :icon="$t('assets.icon')"/>
                </v-badge>

                <v-icon v-else :icon="$t('assets.icon')"/>
              </v-btn>
            </template>
          </v-tooltip>

          <v-tooltip :text="showNearby ? $t('map.hideNearby') : $t('map.showNearby')" location="end">
            <template #activator="{ props }">
              <v-btn
                v-if="!disableShowNearby"
                v-bind="props"
                color="surface"
                rounded="sm"
                density="compact"
                icon
                :aria-label="showNearby ? $t('map.hideNearby') : $t('map.showNearby')"
                style="width: 40px; min-height: 40px"
                @click="() => showNearby = !showNearby"
              >
                <v-icon :icon="showNearby ? 'explore' : 'explore_off'"/>
              </v-btn>
            </template>
          </v-tooltip>

          <v-tooltip :text="$t('map.type')" location="end">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                color="surface"
                rounded="sm"
                density="compact"
                icon
                :aria-label="$t('map.type')"
                style="width: 40px; min-height: 40px"
                @click="changeMapType"
              >
                <v-icon :icon="mapType === 'roadmap' ? 'landscape' : 'satellite'"/>
              </v-btn>
            </template>
          </v-tooltip>

          <v-tooltip :text="$t('map.recenter')" location="end">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                color="surface"
                rounded="sm"
                density="compact"
                icon
                :aria-label="$t('map.recenter')"
                style="width: 40px; min-height: 40px"
                @click="recenter"
              >
                <v-icon icon="my_location"/>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
      </div>

      <div class="position-absolute" style="margin: 10px; top: 0; right: 0;">
        <asset-info-window v-if="selectedAsset" :asset="selectedAsset" @close="() => selectedAsset = null" />
        <span v-else class="d-flex flex-wrap" style="gap: 8px;">
          <slot name="absolute-top-right" />

          <v-tooltip :text="$t('fullscreen')" location="start">
            <template #activator="{ props }">
              <v-btn
                v-if="showMapInnerControls && fullscreenCapable"
                v-bind="props"
                color="surface"
                rounded="sm"
                density="compact"
                icon="fullscreen"
                :aria-label="$t('fullscreen')"
                style="width: 40px; min-height: 40px"
                @click="() => toggleFullscreen()"
              />
            </template>
          </v-tooltip>
        </span>
      </div>

      <div class="position-absolute" style="margin: 10px; margin-bottom: 24px; bottom: 0; right: 0;">
        <v-card rounded="sm">
          <v-btn
            color="surface"
            rounded="sm"
            density="compact"
            icon
            :aria-label="$t('googleMaps.zoomIn')"
            style="min-width: 40px; min-height: 40px"
            @click="() => map?.setZoom((map.getZoom() ?? 0) + 1)"
          >
            <v-icon icon="add"/>
          </v-btn>

          <v-divider />

          <v-btn
            color="surface"
            rounded="sm"
            density="compact"
            icon
            :aria-label="$t('googleMaps.zoomOut')"
            style="min-width: 40px; min-height: 40px"
            @click="() => map?.setZoom((map.getZoom() ?? 0) - 1)"
          >
            <v-icon icon="remove"/>
          </v-btn>
        </v-card>
      </div>

      <side-drawer
        :title="$t('map.layers')"
        :model-value="mapControlTab === MapControlTab.LAYERS"
        style="filter: opacity(0.95)"
        @update:model-value="() => mapControlTab = MapControlTab.CONTROLS"
      >
        <v-list :opened="['general', 'geoLayersDuoPP', 'geoLayers']">
          <v-list-group value="general">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="$t('general')"/>
            </template>

            <template #default>
              <v-list-item class="py-0">
                <v-checkbox v-model="showRelationLines" density="comfortable" hide-details :label="$t('map.showRelationLines')" />
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-group v-if="settings?.geoLayersDuoPP" value="geoLayersDuoPP">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="'DuoPP GWSW'"/>
            </template>

            <template #default>
              <v-list-item v-for="({ layer, enabled, legendUrl }, index) of geoLayersDuoPP" :key="(layer as string)" class="py-0">
                <v-checkbox v-model="geoLayersDuoPP[index].enabled" hide-details density="comfortable" :label="$te(`geoLayersDuoPP.${layer}`) ? $t(`geoLayersDuoPP.${layer}`) : (layer as string)" />
                <v-img v-if="enabled && legendUrl" :src="(legendUrl as string)" :alt="`Legend: ${layer}`" width="auto" />
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-group v-if="settings?.geoLayers" value="geoLayers">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="'PDOK'"/>
            </template>

            <template #default>
              <v-list-item v-for="({ layer, enabled, legendUrl }, index) of geoLayers" :key="(layer as string)" class="py-0">
                <v-checkbox v-model="geoLayers[index].enabled" hide-details density="comfortable" :label="$te(`geoLayers.${layer}`) ? $t(`geoLayers.${layer}`) : (layer as string)" />
                <v-img v-if="enabled && legendUrl" :src="(legendUrl as string)" :alt="`Legend: ${layer}`" width="auto" />
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>
      </side-drawer>

      <side-drawer
        :title="$t('map.filter')"
        :model-value="mapControlTab === MapControlTab.FILTERS"
        style="filter: opacity(0.95)"
        @update:model-value="() => mapControlTab = MapControlTab.CONTROLS"
      >
        <slot name="filter" :assets="assets">
          <v-list :opened="['categories', 'cities']">
            <v-list-group value="categories">
              <template #activator="{ props }">
                <v-list-item v-bind="props">
                  <template #title>
                    <span>{{ $t('categories') }}</span>
                    <v-badge v-if="categoryFilters.length > 0" inline color="error" :content="categoryFilters.length" />
                  </template>
                </v-list-item>
              </template>

              <template #default>
                <v-list-item v-for="{ _id, description } of categories" :key="_id" class="py-0">
                  <v-checkbox v-model="categoryFilters" hide-details density="comfortable" :label="description" :value="_id" />
                  <template #append>({{ allAssets.filter((asset) => asset.category === _id).length }})</template>
                </v-list-item>
              </template>
            </v-list-group>

            <v-list-group value="cities">
              <template #activator="{ props }">
                <v-list-item v-bind="props">
                  <template #title>
                    <span>{{ $t('cities') }}</span>
                    <v-badge v-if="cityFilters.length > 0" inline color="error" :content="cityFilters.length" />
                  </template>
                </v-list-item>
              </template>

              <template #default>
                <v-list-item v-for="{ description } of cities" :key="description" class="py-0">
                  <v-checkbox v-model="cityFilters" hide-details density="comfortable" :label="description" :value="description" />
                  <template #append>({{ allAssets.filter((asset) => asset.location?.city === description).length }})</template>
                </v-list-item>
              </template>
            </v-list-group>
          </v-list>
        </slot>
      </side-drawer>

      <side-drawer
        right
        :title="$t('map.layerFeatures')"
        style="filter: opacity(0.95)"
        :model-value="mapFeatures.length > 0"
        @update:model-value="() => mapFeatures = []"
      >
        <v-list>
          <v-list-group v-for="mapFeature in mapFeatures" :key="mapFeature.layerKey">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="$t(`geoLayers.${mapFeature.layerKey}`)"/>
            </template>

            <template #default>
              <div v-for="(feature, index) of mapFeature.features" :key="index">
                <v-list-item v-for="([propertyKey, propertyValue]) of Object.entries(feature.properties).filter(([_key, value]) => !!value)" :key="propertyKey" :title="propertyKey" :subtitle="propertyValue"/>
              </div>
            </template>
          </v-list-group>
        </v-list>
      </side-drawer>
    </div>

    <inner-loading :showing="loading" :label="$t('loading')" />
  </div>
</template>

<script setup lang="ts">
import debounce from "lodash-es/debounce"
import isEqual from "lodash-es/isEqual"
import { mapDarkStyle, mapLightStyle, geoPointToLatLng, assetRelationToPath } from "~~/utils/map-helpers"

enum MapControlTab {
  CONTROLS = "CONTROLS",
  LAYERS = "LAYERS",
  FILTERS = "FILTERS",
}

const authStore = useAuthStore()
const organizationStore = useOrganizationStore()
const assetStore = useAssetStore()
const cityStore = useCityStore()
const { organizations: currentOrganizationIds } = storeToRefs(authStore)
const { categories } = storeToRefs(assetStore)
const { items: cities } = storeToRefs(cityStore)

const { geolocationEnabled, showMapInnerControls } = useUserPreferences()

const { hasScope } = authStore

const emit = defineEmits(["dragend"])
const props = defineProps<{
  style?: unknown
  class?: unknown
  disableShowNearby?: boolean
  center?: { lng: number; lat: number }
  zoom?: number
  mainAsset?: LookupAsset
  centerOnMainAsset?: boolean
  showMainAssetRelations?: boolean
  draggableMainAsset?: boolean
  assets?: Array<LookupAsset>
  loading?: boolean
}>()

const { mainAsset, showMainAssetRelations, assets, center } = toRefs(props)

useLazyAsyncData("city-filters", () => cityStore.getItems(false))
useLazyAsyncData("categories", () => assetStore.getCategories())
const { data: settings } = useLazyAsyncData("map-settings", () => organizationStore.getSettings(currentOrganizationIds.value![0]))

const mapWrapper = ref<HTMLElement>()
const map = ref<google.maps.Map>()
const mapReference = ref()

const internalCenter = ref(center?.value || defaultCoords)
const mapType = ref("roadmap")
const showNearby = ref(false)
const nearbyAssets = ref<Array<LookupAsset>>([])
const mapControlTab = ref(MapControlTab.CONTROLS)
const showMapContent = ref(false)

const categoryFilters = ref<Array<string>>([])
const cityFilters = ref<Array<string>>([])

const selectedAsset = ref<LookupAsset | null>(null)
const selectedAssetRelations = ref<Array<PopulatedAssetRelation>>([])
const mainAssetRelations = ref<Array<PopulatedAssetRelation>>([])

const geoLayers = ref<Array<SamMapLayer>>([])
const geoLayersDuoPP = ref<Array<SamMapLayer>>([])
const showRelationLines = useLocalStorage("show-relation-lines", true)

const allAssets = computed(() => {
  const candidateAssets: Array<LookupAsset> = []

  if (mainAsset?.value) {
    candidateAssets.push(mainAsset?.value)
  }

  if (mainAsset?.value && showMainAssetRelations?.value) {
    candidateAssets.push(
      ...mainAssetRelations.value.map((relation) => (relation.asset1._id === mainAsset?.value!._id ? relation.asset2 : relation.asset1))
    )
  }

  if (assets?.value) {
    const existingIds = candidateAssets.map((asset) => asset._id) || []
    candidateAssets.push(...(assets?.value.filter((asset) => !existingIds.includes(asset._id)) ?? []))
  }

  if (showNearby.value) {
    const existingIds = candidateAssets.map((asset) => asset._id) || []
    const nearby = nearbyAssets.value.filter((asset) => !existingIds.includes(asset._id))

    candidateAssets.push(...nearby)
  }

  return candidateAssets.filter((asset) => !!asset.location?.gps)
})

const filteredAssets = computed(() =>
  allAssets.value.filter((asset) => {
    if (mainAsset?.value && asset._id === mainAsset?.value._id) {
      return true
    }

    if (categoryFilters.value.length && !categoryFilters.value.includes(asset.category || "")) {
      return false
    }

    if (cityFilters.value.length && !cityFilters.value.includes(asset.location?.city || "")) {
      return false
    }

    return true
  })
)

const assetsForMarkers = computed(() => {
  const categoryMapper = new Map<string, AssetCategory>()
  categories.value.forEach((category) => categoryMapper.set(category._id, category))

  return filteredAssets.value.map((asset) => {
    const category = categoryMapper.get(asset.category!)

    return {
      ...asset,
      markerLabel: category?.marker.description,
      markerIcon: generateMarkerIcon({
        color: category?.marker.color,
        size: asset._id === mainAsset?.value?._id ? MarkerSize.LARGE : category?.marker.size,
        highlighted: asset._id === selectedAsset.value?._id,
        label: category?.marker.description,
        showBadge: !!(asset.ticketCount?.action || asset.ticketCount?.inspection || asset.ticketCount?.malfunction),
      }),
    }
  })
})

const drawerOpen = computed(() => mapControlTab.value === MapControlTab.LAYERS || mapControlTab.value === MapControlTab.FILTERS)

const recenter = () => {
  if (center?.value) {
    map.value?.setCenter(internalCenter.value)
  } else {
    const coords = filteredAssets.value
    .map((asset) => asset.location?.gps?.coordinates)
    .filter((coord): coord is [lng: number, lat: number] => !!coord)
    fitBounds(coords)
  }
}
const setSelectedAsset = (asset: LookupAsset | null) => (selectedAsset.value = asset)
const changeMapType = () =>
  (mapType.value = mapType.value === google.maps.MapTypeId.ROADMAP ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP)

const fitBounds = debounce((coords: Array<[lng: number, lat: number]>) => {
  const bounds = new google.maps.LatLngBounds()
  coords.forEach((coord) => bounds.extend(new google.maps.LatLng(coord[1], coord[0])))
  map.value?.fitBounds(bounds)

  const zoom = map.value?.getZoom() || 0
  if (zoom > 15) {
    map.value?.setZoom(15)
  }
}, 400)

watch(
  filteredAssets,
  () => {
    if (center?.value || !filteredAssets.value.length) {
      internalCenter.value = center?.value || defaultCoords
      return
    }
    if (mainAsset?.value && props.centerOnMainAsset) {
      internalCenter.value = geoPointToLatLng(mainAsset?.value.location?.gps)
      return
    }

    const assetCoordinates = filteredAssets.value
      .map((asset) => asset.location?.gps?.coordinates)
      .filter((coord): coord is [lng: number, lat: number] => !!coord)

    internalCenter.value = generateCenter(assetCoordinates)

    // check if map is already initialized and visible on screen
    if (map.value && mapWrapper.value?.offsetWidth) {
      fitBounds(assetCoordinates)
    }
  },
  { deep: true, immediate: true }
)

watch(showNearby, async () => {
  if (!showNearby.value) {
    return
  }

  nearbyAssets.value = await assetStore.getAssetsNearbyCoords(internalCenter.value.lng, internalCenter.value.lat, 1000)
})

watch(
  selectedAsset,
  async () => {
    if (!selectedAsset.value || !hasScope(selectedAsset.value.organization, AuthScope.CAN_VIEW_ASSET_RELATIONS)) {
      selectedAssetRelations.value = []
      return
    }

    selectedAssetRelations.value = await assetStore.getRelationsByAssetId(selectedAsset.value._id)
  },
  { deep: true }
)

watch(
  () => mainAsset?.value,
  async (newVal, oldVal) => {
    if (isEqual(oldVal, newVal)) return

    if (!mainAsset?.value  || !hasScope(mainAsset.value.organization, AuthScope.CAN_VIEW_ASSET_RELATIONS)) {
      return
    }

    if (showMainAssetRelations?.value) {
      mainAssetRelations.value = await assetStore.getRelationsByAssetId(mainAsset?.value._id)
    }

    // fetch new nearby assets when main asset changes
    if (mainAsset?.value.location?.gps?.coordinates && showNearby.value){
      nearbyAssets.value = await assetStore.getAssetsNearbyCoords(mainAsset.value.location.gps.coordinates[0], mainAsset.value.location.gps.coordinates[1], 1000)
    }
  },
  { deep: true, immediate: true }
)

watch(
  () => [settings.value, !!map.value],
  () => {
    if (!settings.value || !map.value) {
      return
    }

    if (settings.value.geoLayers) {
      const defaultGeoLayers = ["BeheerGebied", "BeheerPut", "BeheerLeiding", "BeheerPomp", "BeheerLozing", "BeheerBouwwerk", "AansluitingLeiding", "AansluitingPunt"]
      geoLayers.value = defaultGeoLayers.map((layer, index) => useMapLayerForGoogleMaps(map, index, layer))
    }
    if (settings.value.geoLayersDuoPP) {
      geoLayersDuoPP.value = settings.value.geoDuoPPCategories
        .filter((mapCategory) => mapCategory.enabled)
        .map((mapCategory, index) => useMapLayerForGoogleMaps(map, index, mapCategory.key, settings.value?.geoLayersDuoPPOrganizationName))
    }
  }, { deep: true, immediate: true }
)

watch(
  () => center?.value,
  () => {
    if (!center?.value) {
      return
    }

    internalCenter.value = center?.value
  },
  { deep: true, immediate: true }
)

const toggleFullscreen = async () => {
  if (!fullscreenCapable.value) {
    return
  }

  if (typeof document !== 'undefined' && document.fullscreenElement === mapWrapper.value) {
    await document.exitFullscreen()
  } else {
    await mapWrapper.value?.requestFullscreen()
  }
}

const fullscreenCapable = computed(() => {
  return typeof document !== 'undefined' && document.fullscreenEnabled && mapWrapper.value
})

const oldMapWrapperWidth = ref(0)
const mapFeatures = ref<Array<MapFeatureData>>([])

onMounted(async () => {
  map.value = await mapReference.value.$mapPromise

  map.value?.addListener("click", async (e: { latLng: google.maps.LatLng }) => {
    if (!map.value) {
      return
    }

    const activeLayers = map.value?.overlayMapTypes
      .getArray()
      .map((mapType) => mapType?.name)
      .filter((name): name is string => !!name)

    const scaleFactor = Math.pow(2, map.value.getZoom() || 0)
    const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    mapFeatures.value = await getLayerFeatures(scaleFactor, latLng, activeLayers)
  })

  recenter()

  // add resizeObserver to mapWrapper to fitBounds map when mapWrapper is resized
  const resizeObserver = new ResizeObserver((entries) =>
    // Use setTimeout to prevent error 'ResizeObserver loop completed with undelivered notifications'.
    // https://github.com/juggle/resize-observer/issues/103#issuecomment-1711148285
    setTimeout(() => {
      if (!map.value) {
        return
      }

      if (entries[0].contentRect.width === oldMapWrapperWidth.value) {
        return
      }

      oldMapWrapperWidth.value = entries[0].contentRect.width

      if (filteredAssets.value.length && !center?.value) {
        const coords = filteredAssets.value
          .map((asset) => asset.location?.gps?.coordinates)
          .filter((coord): coord is [lng: number, lat: number] => !!coord)
        fitBounds(coords)
      }
    }, 0)
  )

  resizeObserver.observe(mapWrapper.value as Element)

  setTimeout(() => showMapContent.value = true, 1000)

  if (filteredAssets.value.length && !center?.value) {
    const coords = filteredAssets.value
      .map((asset) => asset.location?.gps?.coordinates)
      .filter((coord): coord is [lng: number, lat: number] => !!coord)
    fitBounds(coords)
  }
})

onUnmounted(() => {
  if (map.value) {
    map.value = undefined
  }
})
</script>
