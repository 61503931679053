<template>
  <nuxt-link :to="listCard ? `/assets/${asset._id}` : ''" class="block">
    <v-card class="overflow-hidden" :style="{ width: width ?? '320px' }">
      <v-img :src="hasScope(props.asset.organization, AuthScope.CAN_VIEW_FILES) ? thumbnailUrl : 'no-image.png'" cover :height="height ?? '200px'">
        <v-list style="background-color: rgba(0, 0, 0, 0.4) !important" bg-color="surface" theme="dark">
          <v-list-item class="pr-1">
            <v-list-item-title>
              {{ asset.key }} | {{ asset.description }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ findCategory(asset.category || "")?.description }} ({{ asset.template }})
              {{ asset.location?.distance ? prettifyDistance(asset.location.distance) : "" }}
            </v-list-item-subtitle>

            <template v-if="!listCard" #append>
              <v-btn icon="close" variant="text" @click="() => emit('close')" />
            </template>
          </v-list-item>
        </v-list>

        <v-spacer/>

        <v-card-actions class="position-absolute" style="bottom: 0; left: 0; right: 0;">
          <v-tooltip location="top">
            <template #activator="{ props: tooltipProps }">
              <v-btn
                v-bind="tooltipProps"
                color="surface"
                rounded="sm"
                density="compact"
                variant="elevated"
                :aria-label="`${$t('to')} ${$t('assets.asset')}`"
                style="min-width: 40px; min-height: 40px"
                :to="`/assets/${asset._id}`"
                @click="() => emit('close')"
              >
                {{ $t('open') }}
              </v-btn>
            </template>
            <template #default>
              <span>{{ $t("to") }} {{ $t("assets.asset") }}</span>
            </template>
          </v-tooltip>

          <v-tooltip location="top">
            <template #activator="{ props: tooltipProps }">
              <v-btn
                v-bind="tooltipProps"
                color="surface"
                rounded="sm"
                density="compact"
                variant="elevated"
                icon
                :aria-label="`${$t('to')} ${$t('inspections.title')}`"
                style="min-width: 40px; min-height: 40px"
                :to="`/assets/${asset._id}?tab=INSPECTIONS`"
                @click="() => emit('close')"
              >
                <v-badge v-if="ticketCount.inspections" color="error" :content="ticketCount.inspections" offset-x="-8" offset-y="-8">
                  <v-icon :icon="$t('inspections.icon')"/>
                </v-badge>
                <v-icon v-else :icon="$t('inspections.icon')"/>
              </v-btn>
            </template>

            <template #default>
              <span>{{ $t("to") }} {{ $t("inspections.title") }}</span>
            </template>
          </v-tooltip>

          <v-tooltip location="top">
            <template #activator="{ props: tooltipProps }">
              <v-btn
                v-bind="tooltipProps"
                color="surface"
                rounded="sm"
                density="compact"
                variant="elevated"
                icon
                :aria-label="`${$t('to')} ${$t('malfunctions.title')}`"
                style="min-width: 40px; min-height: 40px"
                :to="`/assets/${asset._id}?tab=MALFUNCTIONS`"
                @click="() => emit('close')"
              >
                <v-badge v-if="ticketCount.malfunctions" color="error" :content="ticketCount.malfunctions" offset-x="-8" offset-y="-8">
                  <v-icon :icon="$t('malfunctions.icon')"/>
                </v-badge>
                <v-icon v-else :icon="$t('malfunctions.icon')"/>
              </v-btn>
            </template>

            <template #default>
              <span>{{ $t("to") }} {{ $t("malfunctions.title") }}</span>
            </template>
          </v-tooltip>

          <v-tooltip location="top">
            <template #activator="{ props: tooltipProps }">
              <v-btn
                v-bind="tooltipProps"
                color="surface"
                rounded="sm"
                density="compact"
                variant="elevated"
                icon
                :aria-label="`${$t('to')} ${$t('actions.title')}`"
                style="min-width: 40px; min-height: 40px"
                :to="`/assets/${asset._id}?tab=ACTIONS`"
                @click="() => emit('close')"
              >
                <v-badge v-if="ticketCount.actions" color="error" :content="ticketCount.actions" offset-x="-8" offset-y="-8">
                  <v-icon :icon="$t('actions.icon')"/>
                </v-badge>
                <v-icon v-else :icon="$t('actions.icon')"/>
              </v-btn>
            </template>

            <template #default>
              <span>{{ $t("to") }} {{ $t("actions.title") }}</span>
            </template>
          </v-tooltip>

          <v-spacer/>

          <v-tooltip location="top">
            <template #activator="{ props: tooltipProps }">
              <v-btn
                v-if="asset.location?.gps"
                v-bind="tooltipProps"
                color="surface"
                rounded="sm"
                density="compact"
                variant="elevated"
                icon
                :aria-label="`${$t('openMapsAndNavigateToObject')}`"
                style="min-width: 40px; min-height: 40px"
                :href="navigateToDestination(geoPointToLatLng(asset.location.gps))"
                target="_blank"
                @click.stop
              >
                <v-icon icon="directions"/>
              </v-btn>
            </template>
            <template #default>
              <span>{{ $t("openMapsAndNavigateToObject") }}</span>
            </template>
          </v-tooltip>
        </v-card-actions>
      </v-img>
    </v-card>
  </nuxt-link>
</template>

<script setup lang="ts">
import { AuthScope } from "~~/stores/auth.store"
import { navigateToDestination, geoPointToLatLng } from "~~/utils/map-helpers"

const assetStore = useAssetStore()
const fileStore = useFileStore()
const { hasScope, hasAnyScope } = useAuthStore()
const { categories } = storeToRefs(assetStore)

const props = defineProps<{
  asset: LookupAsset
  listCard?: boolean
  width?: string
  height?: string
  square?: boolean
  bordered?: boolean
  flat?: boolean
}>()
const emit = defineEmits(["close"])

const thumbnailPending = ref(false)
const thumbnailUrl = ref<string>()
const ticketCount = ref<TicketCount>({
  actions: 0,
  inspections: 0,
  malfunctions: 0,
})

const findCategory = (id: string) => categories.value.find((category) => category._id === id)
const prettifyDistance = (distance: number) => (distance < 1000 ? `${distance.toFixed(0)} m` : `${(distance / 1000).toFixed(1)} km`)

const updateImage = async () => {
  const imageId = props.asset.images?.[0]

  if (!imageId) {
    thumbnailUrl.value = "/no-image.png"
    return
  }

  try {
    thumbnailPending.value = true
    thumbnailUrl.value = await fileStore.getFileUrl(imageId, "/no-image.png", true)
  } finally {
    thumbnailPending.value = false
  }
}

watch(
  props,
  async () => {
    if (hasScope(props.asset.organization, AuthScope.CAN_VIEW_FILES)) { updateImage() }

    const hasTicketScope = hasAnyScope(props.asset.organization, [AuthScope.CAN_VIEW_ACTIONS, AuthScope.CAN_VIEW_INSPECTIONS, AuthScope.CAN_VIEW_MALFUNCTIONS])

    if (hasTicketScope) {
      ticketCount.value = await assetStore.getTicketCount(props.asset._id, ...DEFAULT_TICKET_STATUSES)
    }
  },
  { deep: true, immediate: true }
)
</script>
