<template>
  <g-map-cluster :algorithm="algorithm" :renderer="{ render }" :zoom-on-click="true">
    <slot />
  </g-map-cluster>
</template>

<script setup lang="ts">
import type { Renderer} from "@googlemaps/markerclusterer"
import { SuperClusterAlgorithm } from "@googlemaps/markerclusterer"

const getIcon = (count: number) => {
  if (count < 8) {
    return { url: "/clusters/m1.png", scaledSize: new google.maps.Size(48, 48) }
  } else if (count < 16) {
    return { url: "/clusters/m2.png", scaledSize: new google.maps.Size(56, 56) }
  } else if (count < 32) {
    return { url: "/clusters/m3.png", scaledSize: new google.maps.Size(66, 66) }
  } else if (count < 64) {
    return { url: "/clusters/m4.png", scaledSize: new google.maps.Size(78, 78) }
  } else {
    return { url: "/clusters/m5.png", scaledSize: new google.maps.Size(89, 89) }
  }
}

const algorithm = new SuperClusterAlgorithm({ minPoints: 6 })

const render: Renderer["render"] = ({ count, position }) => {
  return new google.maps.Marker({
    icon: getIcon(count),
    label: { text: String(count), color: "white" },
    position,
    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
  })
}
</script>
